
import { Component, Vue } from 'vue-property-decorator';

// Vue.component('child', {
//   template: '#child-template',
//   methods: {
//     someMethod(){
//         this.$parent.someMethod();
//     }
//     }
// });

@Component({
  components: {},
})

export default class MapLayoutManager extends Vue {
  layerSelected(layer) {
    this.$emit('myEvent', layer);
  }

  // eslint-disable-next-line camelcase
  establish_new_link() {
    // console.log('Show popup to establish a new link');
  }

  // eslint-disable-next-line camelcase
  map_view_types = [
    {
      name: 'Default',
      icon: 'map',
    },
    {
      name: 'Satellite',
      icon: 'satellite',
    },
    {
      name: 'Terrain',
      icon: 'terrain',
    },
  ];

  layers = [
    {
      name: 'Water valves',
      checked: true,
    },
    {
      name: 'Pipelines',
      checked: true,
    },
    {
      name: 'Rigs',
      checked: false,
    },
    {
      name: 'Wells',
      checked: true,
    },
    {
      name: 'Drilling',
      checked: false,
    },
    {
      name: 'Tank batteries',
      checked: false,
    },
  ];
}
